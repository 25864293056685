// import firebase from 'firebase';

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyDZt3rc6VmHS4MnRDqjS9CH6MtqjtFv45c",
//   authDomain: "image-gallery-bfe03.firebaseapp.com",
//   projectId: "image-gallery-bfe03",
//   storageBucket: "image-gallery-bfe03.appspot.com",
//   messagingSenderId: "544717487326",
//   appId: "1:544717487326:web:d2f3ecc2fa01ca3151964f",
//   measurementId: "G-FKH4EM35GD"
// });

// export default app;




// import firebase from "firebase/app";
// import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyDZt3rc6VmHS4MnRDqjS9CH6MtqjtFv45c",
//   authDomain: "image-gallery-bfe03.firebaseapp.com",
//   projectId: "image-gallery-bfe03",
//   storageBucket: "image-gallery-bfe03.appspot.com",
//   messagingSenderId: "544717487326",
//   appId: "1:544717487326:web:d2f3ecc2fa01ca3151964f",
//   measurementId: "G-FKH4EM35GD"
// };

// firebase.initializeApp(firebaseConfig);
// const storage = firebase.firestore()
// export {
//   storage, firebase as default
// }

import firebase from "firebase/compat/app";
import 'firebase/compat/storage';

const fire = firebase.initializeApp({
  apiKey: "AIzaSyDZt3rc6VmHS4MnRDqjS9CH6MtqjtFv45c",
  authDomain: "image-gallery-bfe03.firebaseapp.com",
  projectId: "image-gallery-bfe03",
  storageBucket: "image-gallery-bfe03.appspot.com",
  messagingSenderId: "544717487326",
  appId: "1:544717487326:web:d2f3ecc2fa01ca3151964f",
  measurementId: "G-FKH4EM35GD"
});

export default fire;