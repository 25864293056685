import { useEffect, useState } from 'react';
import './App.css';
import fire from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

function App() {
  const [fileUrl, setFileUrl] = useState('');
  const [image, setImage] = useState([]);
  const [dis, setDis] = useState(true);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [logged, setLogged] = useState('');
  const db = fire.firestore();
  const auth = fire.auth();

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const storageRef = fire.storage().ref();
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file).then(()=> setDis(false));
    setFileUrl(await fileRef.getDownloadURL());
  }

  const add = (e) => {
    e.preventDefault();
    db.collection('files').add({
      URL : fileUrl,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setDis(true);
  }

  useEffect(()=> {
    db.collection('files').orderBy('timestamp', 'desc').onSnapshot(snap => {
      setImage(snap.docs.map(doc => ({
        id : doc.id,
        img: doc.data().URL
      })))
    })
  },[]);

  function handleUser(e) {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, pass)
      .catch((e) => {
        const error = e.code;
        alert(error);
      })
    setEmail('');
    setPass('');
  }

  auth.onAuthStateChanged((user) => {
    if(user) {
      setLogged(user)
    } else {
      auth.signOut();
      setLogged('');
    }
  })
  return (
    <div className="App">
      <div className="upload">
        <h1>Gallery</h1>
        {(logged) 
          ? (<div className="upload-area">
              <h2>Upload To Gallery</h2>
              <form onSubmit={add}>
                <input type="file" onChange={uploadFile} />
                <button disabled={dis} title={fileUrl} className="add">ADD</button>
              </form>
              <button onClick={() => auth.signOut()}>LOGOUT</button>
            </div>)
          : (<form className='login-form'>
              <h3>ADMIN LOGIN</h3>
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} className='email' placeholder='Email' /><br />
              <input type="password" value={pass} onChange={e => setPass(e.target.value)} className='pass' placeholder='Password' />
              <button onClick={handleUser}>LOGIN</button>
            </form>)}
        
      </div>

      <hr />
      <div className='gallery'>
        {image.map(i => {
          return (<a key={i.id} href={i.img}><img src={i.img} alt='unable to show' /></a>)
        })}
      </div>
    </div>
  );
}

export default App;
